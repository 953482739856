.customer-detail-header-box {
  height: 92px;
  display: flex;

  .name-box {
    .customer-name {
      text-transform: capitalize;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #2b2162;
    }
    .go-back-text {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: #868686;
    }
  }

  .btn-box {
    margin-left: auto;

    .btn-edit {
      min-width: 83px !important;
    }

    .btn-delete {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #db1b1b;
      margin-right: 24px;
    }
  }
}

.customer-detail-card {
  height: 100%;
  background: #ffffff;
  border-radius: 12px;

  .title-session {
    height: 96px;
    display: flex;
    align-items: center;
    padding-left: 27px;
    padding-right: 27px;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
    }
  }

  .left-card {
    background: #f9f8ff;
    border-radius: 40px;
    max-width: 453px;
    height: 100%;
    margin-left: 27px;

    .left-card-image {
      padding: 44px 32px 0px 32px;
      display: flex;
      justify-content: center;

      .image-container {
        background: #eeecff;
        border-radius: 40px;
        height: 171px;
        width: 171px;
      }
    }
    .info-container {
      margin: 42px 32px 0px 32px;
      max-width: 389px;
      height: 200px;

      .text-left {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
      }

      .other-info-box {
        .total {
          padding-top: 23px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0px;
          padding-top: 32px;

          .total-amount {
            margin-left: auto;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
          }
        }
      }
    }
  }

  .detail-container-left {
    padding-left: 54px;
  }

  .detail-container-right {
    padding-left: 24px;
  }

  .text-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #9f9f9f;
    margin-bottom: 8px;
  }

  .text-detail {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #000000;
    margin-bottom: 40px;
  }
}

.responsive {
  .customer-detail-header-box {
    display: contents;

    .name-box {
      .customer-name {
        text-transform: capitalize;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #2b2162;
      }
      .go-back-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #868686;
      }
    }

    .btn-box {
      float: right;
      .btn-delete {
        font-size: 16px;
        line-height: 24px;
      }
      .btn-edit {
        height: 48px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.customer-detail-card-responsive {
  height: 100%;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 24px;
  display: flex;

  .customer-detail-box {
    margin: 20px 18px 24px 18px;
    height: 100%;
    padding-bottom: 12px;
    width: 100%;
    background: #f9f8ff;
    border-radius: 16px;

    .card-image {
      padding: 34px 55px 0px 71px;
      display: flex;
      justify-content: center;

      .image-container {
        background: #eeecff;
        border-radius: 40px;
        height: 171px;
        width: 171px;
      }
    }

    .info-container {
      margin: 38px 16px 0px 16px;

      .text-left {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
      }
      
      .other-info-box {
        .total {
          padding-top: 23px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0px;
          padding-top: 32px;

          .total-amount {
            margin-left: auto;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
          }
        }
      }
    }
  }
}

.general-info-card-responsive {
  background: #ffffff;
  border-radius: 16px;
  margin-top: 32px;
  padding-bottom: 20px;

  .title-session {
    height: 61px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;

    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
    }
  }

  .detail-container {
    padding-left: 18px;

    .text-label {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: #9f9f9f;
      margin-bottom: 8px;
    }

    .text-detail {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: #000000;
      margin-bottom: 32px;
    }
  }
}

.btn-back-icon {
  margin-right: 8px;
}

.stock-table {
  .ant-table-container table > thead > tr {
    > th:first-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    > th:last-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .ant-table-cell {
    font-size: 14px !important;
    padding: 24px 4px;

    .ant-form-item {
      margin: 0px;

      .ant-form-item-explain-error {
        font-size: 11px;
      }
    }

    .shop-input-number,
    .shop-input {
      height: 48px;

      .ant-input-number-input {
        padding-right: 0px;
      }
    }

    .shop-date-picker {
      height: 48px;
    }

    .shop-date-picker input {
      font-size: 15px;
    }
  }

  .non-image {
    height: 48px;
    padding: 10px;
    background-color: unset;

    .btn-upload-image {
      margin: 0;
      padding-bottom: 16px;
      background-color: unset;
      font-size: 15px;
    }
  }

  .have-image {
    padding: 0px;

    .image-product {
      width: 100%;
    }
  }

  .ant-form-item-has-success {
    width: 100%;
  }

  .image-item {
    img {
      height: 48px;
      object-fit: cover;
    }
  }

  .group-btn-upload-image {
    width: 100%;
  }

  .group-btn-upload-image-display {
    display: flex;
  }

  input,
  .ant-input-number-group-addon {
    font-size: 15px;
  }
}

.ant-popover-inner-content {
  padding: 16px;
}

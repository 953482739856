.left-create-product {
  grid-area: leftproduct;
}

.price-product {
  grid-area: priceproduct;
}

.right-create-product {
  grid-area: rightproduct;
}

.grid-container-edit-product {
  display: grid;
  grid-template-columns: 33% 33% calc(33% - 25px);
  grid-template-areas:
    'leftproduct    leftproduct     rightproduct'
    'priceproduct   priceproduct    rightproduct';
  gap: 20px;
}

.title-group {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #000000;
}

.non-image {
  border: 1px dashed #9f9f9f;
  border-radius: 16px;
  height: 176px;
  text-align: center;

  .justify-left {
    justify-content: left !important;
  }

  .image-product {
    min-height: 50%;
    display: flex;
    align-items: end;
    justify-content: center;
    max-height: max-content !important;

    .upload-image-url {
      font-size: 18px;
      text-decoration: underline;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > div {
      width: 100%;
    }
  }

  .text-non-image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 50%;
    width: 100%;
    margin-top: 16px;
    font-size: 16px;
  }
}

.have-image {
  border: unset !important;
  border-radius: unset !important;
  height: unset !important;
  text-align: left;
}

.btn-add-price {
  width: 193px;
  height: 60px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  min-width: unset !important;

  .icon-btn-add-price {
    margin-right: 12px;
  }
}

.icon-delete-price {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  opacity: 0.5;
}

.title-page-create-product {
  font-size: 32px;
  color: #2b2162;
  font-weight: bold;
  margin-bottom: 30px;
}

.img-product-btn-upload-url {
  white-space: nowrap;
}

.ant-select-dropdown {
  border-radius: 16px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.shop-row-page-header {
  .card-header {
    margin-bottom: 0;
  }
}

.list-price {
  .price-item {
    background-color: #fbfaff;
    border-radius: 16px;
    padding-top: 7px;
    padding-bottom: 1px;
  }

  .drag-icon {
    margin-left: 1em;
    margin-bottom: 0.5em;
  }

  .col-title {
    display: flex;
    align-items: center;

    .position-text {
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 0.5em;
    }
  }
}

.ant-select-clear {
  margin-top: -8px;
}

.ant-input-textarea textarea {
  min-height: unset !important;
}

@media (max-width: 500px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      'leftproduct'
      'rightproduct'
      'priceproduct';
    gap: 20px;
  }

  .shop-form-item-btn {
    padding-top: 16px;

    .revert-order-item {
      flex-direction: row-reverse;
    }
  }

  .grid-container-edit-product,
  .grid-container-create-product {
    display: flex;
  }

  .shop-input-number {
    height: 48px !important;
  }

  .icon-delete-price {
    align-items: start !important;
  }

  .list-price {
    .price-item {
      background-color: #fbfaff;
      border-radius: 16px;
      padding-top: 7px;
      padding-bottom: 1px;
    }

    .drag-icon {
      margin-left: 1em;
      margin-bottom: 0.5em;
      margin-top: 18px;
    }

    .col-title {
      .title-center {
        align-self: flex-start;
      }

      .position-text {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 0.5em;
      }
    }

    .ant-form-item-explain-error {
      font-size: 14px;
    }
  }
}

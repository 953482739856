.blog-filter-card {
}

@media (max-width: 428px) {
    .blog-filter-card {
        .fullWidth {
            width: 100% !important;
        }
    }
    
  }
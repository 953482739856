.ordered-products-table{
  .price-discount + .price-value{
    text-decoration: line-through;
    color: lightgray;
  }
  .product-info{
    display: flex;
    flex-direction: column;
    margin-top: 0;
    b{
      display: block;
    }
    p:nth-child(odd){
      display: inline;
    }
    p:not(:nth-child(odd)){
      display: inline;
      margin-left: 5px;
    } 
  }
  .ant-image{
    width: 100px !important;
  }
}
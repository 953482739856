@import '@fontsource/plus-jakarta-sans';
@import './variable';
@import './dashboard';
@import './responsive.scss';

@media (max-width: 500px) {
  .ant-btn-primary {
    height: 48px !important;
    font-size: 16px;
    line-height: 20px;
  }
}

body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-thumb {
  background-color: #50429b;
  border-radius: 2px;
}

// Header and paragraph
h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

.body-1 {
  font-size: 18px !important;
}

.body-2 {
  font-size: 16px !important;
}

.body-3 {
  font-size: 14px !important;
}

.main-content-bg {
  background: #f7f5ff;
  border-radius: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  margin-top: 24px;
  height: 100%;
}

.main-body {
  position: relative;
}

.shop-site-layout {
  background: #ffffff;
  padding-right: 20px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: #50429b;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #817cba;
  }
}

.rc-virtual-list-scrollbar-thumb {
  background: #50429b !important;
  border-radius: 2px !important;
  width: 4px !important;
}

.rc-virtual-list-scrollbar-thumb:hover {
  background: #817cba !important;
}

.shop-card {
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
}

.shop-card-full {
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  min-height: 73.5vh;
}

.h-auto {
  min-height: auto !important;
  height: auto !important;
}

// Button
.ant-btn {
  letter-spacing: 0.3px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  font-style: normal;
  height: 60px;
  border-radius: 16px;
  min-width: 160px;
}

.ant-btn-primary {
  background: #ff8c21;
  border: #ff8c21;
  color: #ffffff;
}

.ant-btn-primary:hover {
  color: #ffffff;
  border: #50429b;
  background: #50429b;
}

.ant-btn-primary:active,
.ant-btn-primary:focus {
  color: #ffffff;
  border: #50429b;
  background: #50429b;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border: rgba(255, 140, 33, 0.5);
  background: rgba(255, 140, 33, 0.5);
  color: #ffffff;
}

.ant-btn-default {
  background: #f7f5ff;
  border: 1px solid #50429b;
  color: #50429b;
}

.ant-btn-default:hover {
  background: #f7f5ff;
  border: 1px solid #50429b;
  color: #50429b;
}

.ant-btn-default:active,
.ant-btn-default:focus {
  color: #50429b;
  border: 1px solid #50429b;
  background: #f7f5ff;
}

.ant-btn-default[disabled],
.ant-btn-default[disabled]:hover,
.ant-btn-default[disabled]:focus,
.ant-btn-default[disabled]:active {
  background: #f7f5ff;
  border: 1px solid rgba(255, 123, 0, 0.5);
  color: rgba(255, 123, 0, 0.5);
}

.shop-cancel-button {
  letter-spacing: 0.3px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  font-style: normal;
  height: 60px;
  border-radius: 16px;
  color: #a5abde;
  border: 1px solid#a5abde;
  padding-left: 18px;
  padding-right: 18px;
  background: #ffffff;
}

.shop-cancel-button.ant-btn-default {
  background: #ffffff;
  border: 1px solid#a5abde;
  color: #a5abde;
}

.shop-cancel-button.ant-btn-default:hover {
  background: #ffffff;
  border: 1px solid#a5abde;
  color: #a5abde;
}

.shop-cancel-button.ant-btn-default:active,
.shop-cancel-button.ant-btn-default:focus {
  background: #ffffff;
  border: 1px solid#a5abde;
  color: #a5abde;
}

.shop-cancel-button.ant-btn-default[disabled],
.shop-cancel-button.ant-btn-default[disabled]:hover,
.shop-cancel-button.ant-btn-default[disabled]:focus,
.shop-cancel-button.ant-btn-default[disabled]:active {
  background: #ffffff;
  border: 1px solid#a5abde;
  color: #a5abde;
}

// Switch
.ant-switch {
  background: #eef1f8;
  border: #a5abde;
  border-radius: 100px;
}

.ant-switch-checked {
  background: #ff8c21;
  border: #fae6d6;
}

.ant-switch-disabled {
  background: #e4e4e4;
  border: #f4f4f4;
}

// Checkbox
.ant-checkbox {
  background: #ffffff;
  border: #a5abde;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  top: 0.3em;
}

.ant-checkbox + span {
  padding-right: 12px;
  padding-left: 12px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #000000;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #ff8c21;
  background: #ff8c21;
  border-radius: 8px;
}

.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border: 1px solid #a5abde;
  border-radius: 8px;
}

.ant-checkbox-inner::after {
  width: 7px;
  height: 13px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  top: 45%;
  left: 25%;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #ff8c21;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.ant-checkbox-disabled {
  background: #e4e4e4;
  border: #f4f4f4;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.ant-checkbox-wrapper-disabled .ant-checkbox-inner,
.ant-checkbox-disabled:hover .ant-checkbox-inner {
  background: #e4e4e4;
  border: #f4f4f4;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

/// Radio button
.ant-radio {
  border: #a5abde;
  width: 24px;
  height: 24px;
}

.ant-radio + span {
  padding-right: 12px;
  padding-left: 12px;
  color: #000000;

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.3px;
  }
}

.ant-radio-inner {
  width: 22px;
  height: 22px;
}

.ant-radio-inner::after {
  background-color: #ff8c21;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
}

.ant-radio-checked .ant-radio-inner:after {
  border-color: #ff8c21;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #fff6e6;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #ff8c21;
}

.ant-radio-wrapper {
  align-items: center;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #ff8c21;
}

.ant-radio-disabled {
  border: 1px solid #f4f4f4;
  width: 24px;
  height: 24px;
}

/// Input
@mixin shop-input-mixin {
  height: 60px;
  font-size: 18px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 12px;

  @media screen and (max-width: 500px) {
    padding: 12px 16px;
  }

  padding: 16px;

  .ant-input {
    font-size: 18px;
  }

  .ant-input:placeholder-shown {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #9f9f9f;
  }

  .ant-input-prefix {
    color: #9f9f9f;
    font-size: 18px;
    padding-right: 5px;
  }

  .ant-input-suffix {
    color: #9f9f9f;
    font-size: 18px;
    padding: 5px;
  }
}

.shop-input {
  @include shop-input-mixin();
}

.shop-input-non-shadow {
  @include shop-input-mixin();
  box-shadow: none;
}

.shop-input:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

.shop-input:focus {
  box-shadow: 0px 1px 4px #50429b;
  border: none;
}

.shop-input-addon-before:hover {
  transition: border 0.3s, box-shadow 0.3s;
  box-shadow: 0px 1px 4px #50429b;
  border-radius: 12px;
  border: none;
}

.shop-input-addon-before.ant-input-group-wrapper-status-error {
  border: 1px solid #ff0000 !important;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%) !important;
}

.shop-input-addon-before {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border: none;
  border-radius: 12px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #000000;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #ffffff;
    border: none;
  }

  input {
    height: 60px;
    font-size: 18px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ant-input,
  .ant-input:focus,
  .ant-input-focused {
    border: none;
    box-shadow: none;
    border-right-width: none;
    outline: 0;
  }

  .ant-input-status-error {
    box-shadow: none !important;
    border: none !important;
    border-radius: 12px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .ant-input-wrapper,
  .ant-input-group-addon {
    border-radius: 12px;
  }

  .ant-input-group-addon {
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: #000000;
    }
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0px 1px 4px #50429b !important;
  border-color: #ffffff !important;
}

.ant-input-status-error,
.ant-input-number-status-error,
.ant-input-affix-wrapper-status-error,
.ant-input-affix-wrapper-status-error:hover,
.ant-input-affix-wrapper-status-error:focus {
  border: 1px solid #ff0000 !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ff0000 !important;
}

.shop-input:placeholder-shown {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
  padding-left: 16px;
}

.shop-input[disabled] {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background: #f7f7f7;
  border: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper-disabled {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background: #f7f7f7;
  border-color: #ffffff;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}

.shop-input-with-count {
  height: 60px;
  font-size: 18px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 12px;
  padding-left: 16px;

  input {
    height: 100% !important;
    background: none;
    box-shadow: none;
    border: none !important;
  }

  .ant-input {
    font-size: 18px;
  }

  .ant-input:placeholder-shown {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #9f9f9f;
  }

  .ant-input-prefix {
    color: #9f9f9f;
    font-size: 18px;
    padding-right: 5px;
  }

  .ant-input-suffix {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.3px;
    color: #9f9f9f;
    padding: 5px;
  }
}

.shop-form-label {
  font-style: normal;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.ant-form-item {
  margin: 0 0 36px;
}

.ant-form-item-explain-error {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #ff0000;
  padding-top: 4px;
  position: absolute;
}

textarea.shop-text-area {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-left: 16px;
  padding-top: 8px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  border: none;
}

textarea.no-resize {
  resize: none;
}

.shop-text-area:placeholder-shown {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
  padding-left: 16px;
}

.shop-text-area:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

.shop-text-area:focus {
  box-shadow: 0px 1px 4px #50429b;
  border: none;
}

.shop-text-area-with-count {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: $main-color;
    border-radius: 2px;
    border-right: 126px $main-color solid;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $main-color;
  }

  textarea {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding-left: 16px;
    padding-top: 8px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    border: none;
    resize: none;
    overflow: auto;
  }

  .ant-input:placeholder-shown {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #9f9f9f;
    padding-left: 16px;
  }

  .ant-input:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border: none;
  }

  .ant-input:focus {
    box-shadow: 0px 1px 4px #50429b;
    border: none;
  }
}

.shop-text-area-with-count.ant-input-textarea-show-count::after {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
  position: absolute;
  right: 16px;
  padding-top: 5px;
}

.ant-tooltip-inner {
  background: #2c2c2c;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  align-items: center;
  padding: 14px 21px;
  gap: 10px;
}

.shop-table-tooltip {
  .shop-table-tooltip-content {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}

.shop-table-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #50429b !important;
}

.shop-text-nowrap {
  float: left;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shop-table {
  .action-column {
    width: 100px;
  }

  .ant-table-cell p {
    font-size: 18px;
  }

  .ant-table-cell {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    .action-column.action-column-center {
      width: 100%;
      text-align: center;
      min-width: 100px;
    }
  }

  .ant-table-bordered {
    border: none !important;
  }

  table thead tr {
    border-radius: 16px;
  }

  table thead tr th {
    text-transform: uppercase;
    background: #f9f8ff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    color: #463796;
    height: 72px;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
    height: 96px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .ant-table-tbody tr {
    &:nth-child(even) {
      td,
      td.ant-table-column-sort {
        background-color: #fff;
      }
    }

    &:nth-child(odd) {
      td,
      td.ant-table-column-sort {
        background-color: #f7f5ff;
      }
    }

    &:hover {
      td,
      td.ant-table-column-sort {
        background-color: #e6e0fc;
      }
    }
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #f9f8ff;
  }

  .ant-table-thead {
    .ant-table-cell,
    .ant-table-cell.ant-table-column-has-sorters {
      background-color: #f7f5ff;

      &:hover {
        background-color: #f7f5ff;
      }

      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: #2b2162;
      }
    }
  }

  .ant-table-container table > thead > tr > th:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 16px;
  }

  .ant-table-container table > thead > tr > th:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 16px;
  }

  .ant-table-selection-column {
    padding-right: 16px !important;
  }

  .ant-table-container table > thead > tr > th:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  table tbody tr:nth-of-type(odd) {
    background: #f9f8ff;
  }

  .ant-table-container table > tbody > tr > td:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 16px;
  }

  .ant-table-container table > tbody > tr > td:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .ant-pagination {
    li {
      background: #fbfbfb;
      border-radius: 16px;
      width: 45px;
      height: 44px;
      display: flex;

      button {
        background: #fbfbfb;
        border-radius: 16px;
        width: 45px;
        height: 44px;
      }
    }

    .ant-pagination-item,
    .ant-pagination-item-active {
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin: auto;
      }
    }

    .ant-pagination-item {
      a {
        color: #2c2c2c;
      }
    }

    .ant-pagination-item:hover {
      border-color: #817cba;
      background: #817cba;

      a {
        color: #ffffff;
      }
    }

    .ant-pagination-item-active {
      background: #50429b;
      border-color: #50429b;

      a {
        color: #ffffff;
      }
    }
  }
}

.shop-table-action-button {
  display: flex;

  svg,
  span {
    margin: auto;
  }
}

.shop-table-action-button:hover,
.shop-table-action-button:focus {
  color: #50429b;
  border: #ffffff;
  background: #ffffff;
}

.shop-search-input {
  border: none !important;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e2dfef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  input,
  input:placeholder-shown {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #9f9f9f;
  }

  .ant-input-prefix {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.shop-search-input:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
  box-shadow: 0px 1px 4px #50429b;
}

.text-danger {
  color: #db1b1b;
}

.shop-input-with-prefix {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shop-input-with-prefix input {
  outline: none !important;
  border-color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
}

.shop-input-with-prefix input:hover,
.shop-input-with-prefix input:focus {
  border: 0px #ffffff !important;
  outline: none !important;
  box-shadow: none;
}

.shop-input-with-prefix .ant-input-group-addon {
  background: #ffffff;
  border: 0px;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #000000;
}

.shop-input-with-prefix .ant-input-status-error,
.shop-input-with-prefix .ant-input-status-error:focus {
  border: 0px !important;
  box-shadow: none !important;
  outline: none !important;
}

.shop-input-with-prefix .ant-input:focus,
.shop-input-with-prefix .ant-input-focused {
  box-shadow: none !important;
}

.col-input-full-width .ant-col-22 {
  max-width: 100% !important;
}

.btn-cancel {
  color: #a5abde;
  border: 1px solid #a5abde;
}

.ant-picker-cell-selected > div.ant-picker-cell-inner {
  background: #50429b !important;
  border-radius: 4px;
}

.ant-picker-cell-selected > div.ant-picker-cell-inner::before {
  border-color: #50429b !important;
}

.ant-picker-cell-today > div.ant-picker-cell-inner::before {
  border-color: #50429b !important;
}

.ant-picker-today-btn {
  color: #50429b !important;
}

.ant-picker-header {
  background: #50429b !important;
}

.ant-picker-header * {
  color: #dadada;
}

.ant-picker-header button:hover > span {
  color: #ffffff;
  font-weight: bold;
}

.ant-picker-header div.ant-picker-header-view > button:hover {
  color: #ffffff !important;
}

.shop-table-action-icon:hover > svg > path {
  fill: #50429b;
  stroke: #50429b;
}

.icon-svg-hover:hover path {
  fill: #50429b;
  stroke: #50429b;
}

.icon-svg-hover:hover circle {
  fill: #50429b;
  stroke: #50429b;
}

.content-options-popover {
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px !important;
}

.second-button {
  background: unset;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #50429b;
  height: 60px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  border: 0px;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
}

.second-button:hover {
  background: unset !important;
  color: #50429b;
  border: 0px;
  box-shadow: none;
  text-shadow: none;
}

div.shop-box.custom-box {
  border-radius: 12px;
}

.title-content-options-popover {
  background: #50429b;
  height: 71px;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  color: white;
  align-items: center;
  border-radius: 16px 16px 0 0;
}

.btn-cancel-form {
  color: #50429b;
  font-weight: 700;
  border: unset;
  font-size: 18px;
  background: inherit;
  width: 130px;
  height: 60px;
}

.btn-cancel-form:hover {
  color: #50429b;
  font-weight: bold;
  border: unset;
  font-size: 18px;
  border: 1px solid #50429b;
}

.hidden {
  display: none;
}

.icon-btn-add-option {
  border: 1px solid #fff;
  background: #fff !important;
  color: #ff8c21 !important;
  border-radius: 4px;
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.btn-create-form {
  width: 133px;
  height: 60px;
}

.shop-input-number {
  height: 60px;
  font-size: 18px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  border: none;

  .ant-input-number-input-wrap {
    height: 100%;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input {
    padding-left: 16px;
  }
}

.shop-input-number:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

.shop-input-number:focus,
.shop-input-number:visited,
.shop-input-number:focus-within {
  box-shadow: 0px 1px 4px #50429b;
  border: none;
}

.shop-input-number:placeholder-shown {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
  padding-left: 16px;
}

.shop-input-number[disabled] {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background: #f7f7f7;
  border-color: #ffffff;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-group-addon {
  background: transparent;
  border-radius: 0 16px 16px 0;
  opacity: 0.5;
  font-size: 18px;
  border: none;
}

.ant-input-number-wrapper.ant-input-number-group {
  .shop-input-number {
    box-shadow: none;
  }
}

.label-information {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.justify-content-center {
  justify-content: center !important;
}

.shop-date-picker {
  height: 60px;
  font-size: 18px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
  padding-left: 16px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  input {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    position: absolute;
    float: left;
    padding-left: 40px;
  }

  .ant-picker-suffix {
    width: 20px;
  }
}

.shop-date-picker:hover {
  box-shadow: 0px 1px 4px #50429b;
  border-radius: 12px;
  border: none;
}

.shop-text-action-group {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  height: 60px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.3px;
  margin: auto;
  color: $main-color;
}

.cursor-pointer {
  cursor: pointer !important;
}

.shop-card-store-logo {
  background: #ffffff;
  border-radius: 30px;

  .ant-card-body {
    .shop-card-store-logo-body {
      .store-logo-suggest-content {
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;

        .store-logo-suggest-information {
          .store-logo-suggest-information-title {
            font-size: 24px;
            font-weight: 700;
          }

          .store-logo-suggest-information-sub-title {
            font-size: 20px;
          }

          .store-logo-suggest-information-sub-subtitle {
            color: #5e5e5e;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: start;
            margin-left: 5px;

            .store-logo-dot {
              width: 5px;
              height: 5px;
              background: #000;
              border-radius: 50%;
              display: inline-flex;
            }

            .store-logo-text {
              margin-left: 10px;
            }
          }
        }
      }

      .image-store-logo-suggest-content {
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;

        .store-logo-content-none-background {
          background: none !important;
        }

        .store-logo-content {
          width: 666px;
          height: 353px;
          background: #f7f5ff;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 100%;

          .store-none-logo {
            border: 1px dashed #9f9f9f;
            border-radius: 16px;
            text-align: center;
            min-height: 305px;
            width: 618px;
            max-width: 90%;
            background: #fff;

            .justify-right {
              justify-content: right !important;
            }

            .image-product {
              min-height: 50%;
              display: flex;
              align-items: end;
              justify-content: center;
              max-height: max-content !important;

              .upload-image-url {
                font-size: 18px;
                text-decoration: underline;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .text-non-image {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              height: 50%;
              width: 100%;
              margin-top: 16px;
              font-size: 16px;
            }
          }
        }

        .store-have-logo {
          border: unset !important;
          border-radius: unset !important;
          height: unset !important;
          text-align: left;
        }
      }
    }
  }
}

div.ant-message-notice-content {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  border-radius: 16px;
  padding: 0;

  .ant-message-success {
    padding: 10px 16px;
    height: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #33b530;
    background: #ecf5ed;
    border: 1px solid #33b530;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  .ant-message-error {
    padding: 10px 16px;
    height: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    background: #f5ecec;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    color: #db1b1b;
    border: 1px solid #db1b1b;
  }

  .ant-message-warning {
    padding: 10px 16px;
    height: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    background: #f5f4ec;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    color: #faad14;
    border: 1px solid #faad14;
  }
}

.shop-tabs {
  .ant-tabs-nav {
    height: 108px;
    background: #ffffff;
    border-radius: 16px;
  }

  .ant-tabs-nav-list {
    display: inline-flex;
  }

  .ant-tabs-nav-wrap {
    padding-left: 24px;
    padding-right: 24px;
    height: 60px;
    margin: auto 0;
  }

  .ant-tabs-tab {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    background: #f7f5ff;
    border-radius: 12px;
    height: 100%;
    text-transform: uppercase;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.3px;
    color: #50429b;
  }

  .ant-tabs-tab-active {
    background: #50429b;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.3px;

    .ant-tabs-tab-btn {
      color: white;
    }
  }

  .ant-tabs-ink-bar {
    background: none;
  }
}

.ant-picker-dropdown {
  z-index: 9999;
}

.shop-collapse {
  border: none;
  border-radius: 24px;

  .ant-collapse-item {
    background: #ffffff;
    border-radius: 24px !important;
    border-bottom: none;
  }

  .ant-collapse-item:last-child {
    border-radius: 24px !important;
  }

  .ant-collapse-content {
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;

    .ant-collapse-content-box {
      padding: 0 30px 30px 30px;
    }
  }

  .ant-collapse-header {
    color: #2b2162 !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    height: 104px;

    div {
      margin: auto 0;
    }

    div:first-child {
      order: 2;
      margin-right: 10px;

      span {
        font-size: 24px;
      }
    }

    div:last-child {
      width: 100%;
      margin-left: 10px;
    }

    .ant-collapse-arrow {
      font-size: 16px !important;
    }
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse-content-box {
    padding-left: 30px;
  }
}

.justify-space-between {
  justify-content: space-between;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.opacity-disabled {
  opacity: 0.5;
}

// Tablet & mobile
@media (max-width: 1024px) {
  .shop-site-layout {
    padding-right: 0px;
  }

  .main-content-bg {
    margin-top: 100px;
  }
}

//Tablet
@media (min-width: 992px) and (max-width: 1080px) {
  .shop-date-picker {
    padding-left: 8px;
  }

  .shop-time-picker {
    .ant-picker-input {
      input {
        padding-left: 28px;
      }
    }
  }
}

//Mobile
@media (max-width: 740px) {
  .main-content-bg {
    margin-top: 90px;
  }

  .shop-text-action-group {
    height: 100%;
  }
}
.shop-card-status{
  height: 100px;
  .ant-card-body{
    padding: 12px 50px;
  }
  .ant-form-item{
    margin-bottom: 20px;
  }
}
.edit-title{
  display: flex;
  align-items: center;
  .shop-title-header{
    margin: 0;
  }
}
.email-detail{
  .ant-modal-content{
    border-radius: 10px;
    width: 800px;
    left: -100px;
    .info{
      flex: 1;
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
    }
    .action{
      margin-left: 30px;
      *{
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .ant-image{
    border-radius: 50% !important;
    width: 60px;
    height: 60px;
  }
  .ant-btn{
    border-radius: 30px;
    color: white;
    background-color: blue;
  }
  .response-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 5px;
    &:hover{
      background-color: lightgray
    }
  }
  .response {
    @extend .response-container;
    flex-direction: row;
    align-items: center;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Plus Jakarta Sans";
}

body > iframe#webpack-dev-server-client-overlay {
  display: none;
}
.user-select-none-for-admin {
  user-select: none;
  pointer-events: none;
  .login_phone_error {
    display: none;
  }
}

.icon-edit {
  font-size: 18px;
  color: #135200 !important;
}

.icon-del {
  font-size: 18px;
  color: red !important;
}

.confirm-stop-modal-sizing {
  .ant-space-item {
    .icon-discount-code-stop-fill {
      display: none;
    }
  }
}

.d-flex {
  display: inline-flex !important;
}

.justify-end {
  justify-content: end;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}
.justify-content-between{
  justify-content: space-between;
}
.justify-content-around{
  justify-content: space-around;
}
.justify-content-evenly{
  justify-content: space-evenly;
}

.d-flex-right {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.form-filter-popover {
  width: 350px;
  .form-select {
    width: 200px;
  }
}

.color-primary {
  color: #2d9aff !important;
}

.color-orange {
  color: #fff !important;
  background-color: orange !important;
}

.activate-button {
  color: #000 !important;
  background-color: #f1c232 !important;
}

.activate-button:hover {
  color: #fff !important;
  background-color: #f1c232 !important;
  cursor: pointer !important;
}

.color-gray {
  color: gray !important;
}

.color-black {
  color: black !important;
}

.fs-30 {
  font-size: 30px;
}

.h-100 {
  height: 100%;
}

.pl-100px {
  padding-left: 100px;
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-first-capitalize {
  text-transform: lowercase;
}

.text-first-capitalize:first-letter {
  text-transform: uppercase;
}

#loadingIndicator {
  display: none;
}

#loadingIndicator.loading-indicator {
  position: fixed; /* Use fixed to cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Ensure it sits on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

$text-color: #282828;
$text-primary-color: #fff;
$text-average-section-color: #50429B;
$text-success-color: #33B530;
$text-error-color: #DB1B1B;
$back-ground-color: #6050B2;

.summary-card-average {
  height: 100%;
  border-radius: 16px;
  padding: 12px;
  background-color: $back-ground-color;
  width: 100%;
  background: var(--report-card-bg, linear-gradient(74deg, #50429b 0%, #33257b 100%));

  .section-total {
    margin-bottom: 12px;

    .title {
      color: $text-primary-color;
    }

    .ant-row:nth-child(2) {
      .summary-short-value {
        color: $text-primary-color;
      }
    }

    .ant-row:nth-child(3) {
      .summary-suffix {
        color: $text-primary-color;

        p {
          color: $text-primary-color;
        }
      }
    }
  }

  .section-average {
    background: #fff;
    padding: 12px;
    border-radius: 16px;

    .title {
      color: $text-average-section-color;
    }

    .ant-row:nth-child(2) {
      .summary-short-value {
        color: $text-average-section-color;
      }
    }

    .ant-row:nth-child(3) {
      .summary-suffix {
        color: $text-average-section-color;

        p {
          color: $text-average-section-color;
        }
      }
    }
  }


  .section-total,
  .section-average {
    p {
      margin-bottom: 0;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      margin-left: 15px;
      margin-bottom: 0;
    }

    .ant-row:nth-child(1):not(.acceleration-card .ant-row) {
      align-items: center;
      margin-bottom: 4px;
      
      .ant-col:nth-child(2) {
        flex: 6;
      }

      .ant-col:nth-child(3) {
        flex: 4;
        display: flex;
        justify-content: end;

        .acceleration-card {
          border-radius: 8px;
          width: fit-content;
          padding: 8px;

          .ant-row {
            gap: 5px;
            align-items: center;
            justify-content: center;

            .percent {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
            }

            .decrease {
              color: $text-error-color;
            }

            .increase {
              color: $text-success-color;
            }
          }
        }

        .acceleration-card.increase {
          background-color: #E7F9E7;
          color: #33B530;
        }

        .acceleration-card.decrease {
          background-color: #FFF7F7;
          color: #DB1B1B;
        }
      }
    }

    .ant-row:nth-child(2) {
      margin-bottom: 12px;

      .summary-short-value {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: end;
        gap: 10px;
        margin-left: 5px;

        p {
          line-height: 1;
        }

        p.suffix {
          font-size: 20px;
        }
      }

      .ant-col:nth-child(1) {
        min-width: 36px;
        flex: 0.55;
      }

      .ant-col:nth-child(2) {
        flex: 6;
      }

      .ant-col:nth-child(3) {
        flex: 1;
      }
    }

    .ant-row:nth-child(3) {
      .summary-suffix {
        font-size: 16px;
        font-style: normal;
        display: flex;
        gap: 8px;
        margin-left: 5px;
        text-wrap: nowrap;

        .suffix-text {
          color: #afa1ff;
        }
      }

      .ant-col:nth-child(1) {
        min-width: 36px;
        flex: 0.55;
      }

      .ant-col:nth-child(2) {
        flex: 6;
      }

      .ant-col:nth-child(3) {
        flex: 1;
      }
    }

    .sumnary-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #6050b2;
      height: 100%;
      padding: 8px;
    }
  }
}
@import '../../../../stylesheets/variable.scss';
.email-management{
  .ant-table-wrapper{
    width: 100%;
  }
  .ant-table-row{
    &:hover{
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
      .ant-table-cell:nth-of-type(4){
        width: 50% !important;
      }
      .ant-table-cell:last-of-type{
        width: 20% !important;
      }
    }
    // .ant-table-cell-ellipsis:nth-of-type(3){
    //   overflow: hidden;
    // }
  }
  .ant-table-tbody{
    font-size: medium;
  }
  .ant-pagination-item{
    display: none;
  }
  .is-read{
    background-color: $bg-btn-cancel-color !important;
  }
  .unread{
    font-weight: bold;
  }
  .ant-spin-container{
    position: relative;
    .ant-pagination{
      position: absolute;
      top: 0;
      right: 20px;
      .ant-pagination-item-link{
        background-color: transparent;
        border: none;
      }
    }
  }
  
}
.info-popup-container{
  position: relative;
  cursor: pointer;
}
.user-info-container{
  position: absolute;
  top: 15px;
  left: 40px;
  width: 350px;
  height: 180px;
  background-color: $color-F9FBFF;
  border-radius: 5px;
  z-index: 99;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
  .user-info{
    border-bottom: 1px solid lightgray;
    font-size: medium;
    *{
      text-overflow: ellipsis;
    }
  }
  button{
    border-radius: 5px;
    padding: 12px 20px;
    background-color: $color-ECF1F9;
    color: $color-light-blue;
    border: none;
    cursor: pointer;
  }
}
.delete-email-modal{
  .ant-modal-content{
    border-radius: 20px;
    left: 20px;
    top: 40px;
    transition: 1s;
    *{
      font-size: large;
    }
  }
}
.table-product-size{
  position: relative;
  width: 75% !important;
  top:20px;
  .ant-modal-body{
    height: 600px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .ant-btn{
    position: sticky;
    bottom: 0px;
  }
  .ant-modal-close{
    color: white;
    right: 10px;
    top:10px;
  }
  
}
@import '../../../../stylesheets/variable.scss';
.email-management{
  .ant-tabs, .ant-tabs-nav-list{
    width: 100%;
  }
  .ant-tabs-nav-wrap, .ant-tabs-nav-list{
    justify-content: space-around;
  }
  .ant-tabs-ink-bar{
    width: 100px;
  }
  .ant-tabs-tab{
    flex: 1;
    padding: 20px;
    margin: 0 !important;
    &:hover{
      background-color: $color-ECF1F9;
    }
  }
  
}
.style-icon-view-more {
  width: 28.8px;
  height: 28.8px;
}

.shop-popover-view-more {
  width: 461px;
  border-radius: 16px;

  .title-view-more {
    height: 71px;display: flex;
    padding-left: 24px;
    align-items: center;
    background-color: #50429B;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    p {
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
      color: #FFFFFF;
      display: contents;
      font-family: "Plus Jakarta Sans";
    }
  }

  .data-view-more {
    height: 539px;
    overflow: auto;
    padding: 25px;
    background-color: #FFFFFF;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .form-item {
      height: 94px;
      border-radius: 16px;
      background-color: #FFFFFF;
      align-items: center;
      display: flex;
      padding-left: 12px;

      a {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-family: "Plus Jakarta Sans";
        line-height: 23px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .disabled-link {
        cursor: default;
        pointer-events: none;        
        text-decoration: none;
        color: #000000;
      }
    }

    .form-item:hover {
      background-color: #FBFBFB;
    }
  }
}
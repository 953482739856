@import '../../../stylesheets/variable.scss';
.order-card{
  position: relative;
  height: 350px;
  width: 350px;
    .ant-card-meta-avatar{
        border-radius: 50%;
        background: $color-light-white-purple;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        margin-right: 10px;
    }
    .ant-card{
        background: $color-light-white-purple;
        border-radius: 10px;
    }
    .order-content{
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }
    .scheduled-time{
        background-color: $color-light-green;
        border-radius: 20px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
    }
    .ant-btn-default{
        color: white;
        margin-top: 5px;
    }
    .flex-fill{
        flex-basis: 150px;
        text-align: end;
    }
    hr{
        background-color: white;
    }
    p{
        margin: 0;
    }
    .ant-btn-primary{
        text-transform: uppercase;
        background: $color-purple;
        height: 50px;
        font-size: medium;
    }
    .ant-btn-dangerous{
        color: white;
        border-color: $color-FF8C21;
        background: $color-FF8C21;
    }
    .order-card-content{
        background-color: white !important;
    }
    .btn-container{
      position: absolute;
      left: 0;
      z-index: 99;
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
}

.blog-management-list {
    .boxImage {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bannerImageUrl {
            border-radius: 8px;
        }
    }
    .titleBlog {
        max-width: 400px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        span {
            color: #000;
            font-family: Plus Jakarta Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        } 
    }
    .boxContent {
        max-width: 480px;
        margin-top: 8px;
    }
    .contentBlog {
        color: #000;
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-style: normal;
        letter-spacing: 0.3px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 2;

        font-weight: 500;
        line-height: 24px;
    }
    .textCenter {
        text-align: center;
    }
    .blogTable {
        .ant-table-thead {
            th {
                white-space: nowrap;
            }
        }
        .ant-table-tbody {
            .ant-table-cell {
                .action-column {
                    margin: auto;
                }
            }
        }
        .lastSavedTime {
            white-space: nowrap;
            .lastSavedTimeDate {
                margin-left: 15px;
            }
        }
    }
}
@media (max-width: 428px) {
    .blog-management-list {
        .bannerImageUrl {
            margin-right: 60px;
        }
    }
  }
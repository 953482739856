.keyword-SEO-tag {
  .ant-tag {
    position: relative;
    display: inline-flex;
    min-height: 30px;
    width: max-content;
    border-radius: 10px;
    justify-content: start;
    align-items: center;
    font-size: medium;
    margin: 5px;
    line-height: unset;
  }
}

.table-modal-product {
  .action-column {
    width: 100px;
  }
  .ant-table-cell p {
    font-size: 18px;
  }

  .ant-table-cell {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-table-bordered {
    border: none !important;
  }

  table thead tr {
    border-radius: 16px;
  }

  table thead tr th {
    text-transform: uppercase;
    background: #f9f8ff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    color: #463796;
    height: 72px;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
    height: 96px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: #f9f8ff;
  }

  .ant-table-container table > thead > tr > th:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 16px;
  }
  .ant-table-container table > thead > tr > th:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 16px;
  }

  .ant-table-selection-column {
    padding-right: 16px !important;
  }

  .ant-table-container table > thead > tr > th:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  table tbody tr:nth-of-type(odd) {
    background: #ffffff;
  }

  table tbody tr:nth-of-type(even) {
    background: #f9f8ff;
  }

  .ant-table-container table > tbody > tr > td:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 16px;
  }

  .ant-table-container table > tbody > tr > td:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .ant-pagination {
    li {
      background: #fbfbfb;
      border-radius: 16px;
      width: 45px;
      height: 44px;
      display: flex;

      button {
        background: #fbfbfb;
        border-radius: 16px;
        width: 45px;
        height: 44px;
      }
    }

    .ant-pagination-item,
    .ant-pagination-item-active {
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin: auto;
      }
    }

    .ant-pagination-item {
      a {
        color: #2c2c2c;
      }
    }

    .ant-pagination-item:hover {
      border-color: #817cba;
      background: #817cba;

      a {
        color: #ffffff;
      }
    }

    .ant-pagination-item-active {
      background: #50429b;
      border-color: #50429b;

      a {
        color: #ffffff;
      }
    }
  }
}

// Colors
$color-primary: #fc9434;
$color-active: #e47712;

$color-white: #ffffff;
$color-light-white: #fbfbfb;
$color-purple: #463796;
$color-light-purple-1: #50429b;
$color-light-purple-2: #817cba;
$color-light-white-purple: #ecf1f9;

$color-black: #000000;
$color-light-black-1: #212121;
$color-light-black-2: #2c2c2c;
$color-light-black: #454545;

$color-red: #fc0d1b;
$color-light-red: rgba(219, 27, 27, 0.2);
$color-green: #33b530;
$color-light-green: rgba(51, 181, 48, 0.2);

$color-light-blue: #428bc1;

$bg-header-color: #f7f7f7;
$bg-btn-cancel-color: #f9f9f9;
$bg-overlay-color-1: rgba(0, 0, 0, 0.5);
$bg-overlay-color-2: rgba(0, 0, 0, 0.7);

/// Primary & secondary color
$color-50429B: #50429b;

$color-2B2162: #2b2162;
$color-817CBA: #817cba;
$color-A5ABDE: #a5abde;
$color-ECF1F9: #ecf1f9;
$color-FF8C21: #ff8c21;
$color-FF7B00: #50429b;
$color-FAE6D6: #fae6d6;

/// Extra color
$color-F96E43: #f96e43;
$color-FFF0EC: #fff0ec;
$color-F9CC66: #f9cc66;
$color-FFFAF0: #fffaf0;
$color-33B530: #33b530;
$color-ECF5ED: #ecf5ed;
$color-FC0D1B: #fc0d1b;
$color-FFE6E7: #ffe6e7;
$color-F9FBFF: #f9fbff;
$color-F7F5FF: #f7f5ff;

///Font size
$font-size-20: 20px;


$main-color: #50429b;

:root {
    --btn-color          : #ff8c21;
    --btn-color-horver   : #50429b;
    --border-color       : #f0f0f0;
    --up-spacing-25      : 25px;
    --up-spacing-5       : 5px;
    --up-spacing-absolute: -20px;
}

.c-update-password {
    --media-max-width: calc((100% / 2) - (var(--up-spacing-25) / 2));

    &--border {
        position: relative;

        &::after {
            content     : "";
            display     : block;
            position    : absolute;
            top         : 0;
            left        : 50%;
            transform: translate(-50%, 0);
            clear       : both;
            border-style: solid;
            border-color: var(--border-color);
            width       : 100%;
            height      : 1px;
            border-width: 1px;
        }
    }

    &--spacing {
        margin-top: var(--up-spacing-25);
        padding   : var(--up-spacing-25) 0;
    }

    .up-header {
        width : 100%;
        height: 60px;

        &--spacing {
            margin-bottom: var(--up-spacing-25);
        }
    }

    .title-group {
        float: left;
    }

    .btn-wraper {
        height   : 60px;
        min-width: 60px;
        float    : right;
    }

    .up-btn {
        $btn-color      : #ffffff;
        background-color: var(--btn-color);
        color           : $btn-color;
        text-transform  : capitalize;
        height          : 100%;

        &:hover {
            text-decoration: none;
            background     : var(--btn-color-horver);
            color          : $btn-color;
        }
    }

    .up-content-wrap {
        display        : flex;
        justify-content: space-between;

        .up-content-wrap-textbox-label {
            width         : 100%;
            text-transform: capitalize;
        }

        &--spacing {
            margin-top: var(--up-spacing-25);

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .up-content-wrap-textbox {
        width        : var(--media-max-width);
        position     : relative;
        white-space  : nowrap;
        text-overflow: ellipsis;

        &--spacing {
            margin-bottom: var(--up-spacing-25);
        }
    }

    .up-content-wrap-textbox-input {
        height        : 60px;
        background    : #ffffff;
        box-shadow    : 0px 1px 4px rgb(0 0 0 / 25%);
        border-radius : 12px;
        font-weight   : 400;
        line-height   : 24px;
        padding-left  : 16px;
        padding-top   : 8px;
        align-items   : center;
        letter-spacing: 0.3px;
        border        : none;

        .ant-input,
        .ant-input-suffix {
            font-size: 18px;
        }

    }

    @media (max-width: 768px) {
        .up-content-wrap {
            flex-direction: column;

            .up-content-wrap-textbox {
                width: 100%;
            }
        }

        .up-content-wrap-textbox {
            width   : var(--media-max-width);
            position: relative;

            &--spacing {
                margin-bottom: var(--up-spacing-25);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .up-error {
        bottom       : var(--up-spacing-absolute);
        width        : 100%;
        text-overflow: ellipsis;
        overflow     : hidden;
    }
}
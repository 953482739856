.text-content-notification {
  padding-bottom: 32px;
  max-width: 550px;
}
.table-notification {
  width: 100%;
  max-height: 270px;
  background-color: #F7F5FF;
  border-radius: 16px;
  font-size: 24px;
  padding: 24px 0px;
  overflow-y: scroll;

  .po-row {
    display: flex;
    width: 100%;
    margin: 10px auto;
  }
  .po-row-item {
    width: 50%;
    text-align: left;
    padding-left: 26px;
    a {
      color: #428BC1;
      margin-left: 5px;
    }
    span {
      color: #000;
      min-width: 40px;
      display: inline-block;
      text-align: center;
    }
  }
}
.modal-footer {
  padding-top: 32px;
}
.btn-i-got-it {
  padding-top: 32px;
}

.shop-table-action-icon:hover {
  cursor: pointer;
}

/* Scroll bar */
.table-file::-webkit-scrollbar {
  width: 4px;
}

.table-file::-webkit-scrollbar-thumb {
  outline: none;
  border-radius: 2px;
  background-color: #50429B;
}

.delete-confirm-modal {
  button {
      margin-left: 10px;
      margin-right: 10px;
  }

  .ant-modal-content {
      border-radius: 48px;
  }
  .ant-modal-header {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      background-color: #ECF1F9;
      height: 102px;
      position: relative;
  }
  .ant-modal-title{
      color:#50429B;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }

  .ant-modal-body {
      text-align: center;
      color: #000000;
      font-size: 18px;
      line-height: 36px;
      font-weight: 500;
      padding-top: 32px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .ant-modal-footer {
      text-align: center;
      border-top: none;
      padding-bottom: 8%;
  }
  .ant-btn {
      height: 55px;
      min-width: 183px;
      border-radius: 16px;
  }
  .ant-btn-default {
      min-width: 180px;
      border: 1px solid #A5ABDE;
      color: #A5ABDE;
  }
  .ant-btn-default:hover {
      border: 1px solid #A5ABDE;
      color: #A5ABDE;
  }
  .ant-btn-dangerous {
      background-color: #FC0D1B;
      color: #FFFFFF;
      border: none;
  }
  .ant-btn-dangerous:hover {
      background-color: #d90b17;
      color: #FFFFFF;
      border: none;
  }
}

.style-text-confirm-delete {
  font-weight: bold;
  color: #FC0D1B;
}

.style-text-confirm-noti {
  color: #50429b;
  font-weight: bold;
}
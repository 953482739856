.action-cancel,
.arrow-dropdown-icon {
  color: #50429b !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-style: normal;
  letter-spacing: 0.3px !important;
  min-width: unset !important;
  border: none !important;
  background-color: unset !important;
}

.arrow-dropdown-icon svg {
  margin-left: 12px;
  margin-bottom: 3px;
}

.action-activate {
  color: #33b530 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.3px;
}

.action-activate:hover {
  color: #227a20 !important;
}

.action-deactivate {
  color: #f3b13e !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.3px;
}

.action-deactivate:hover {
  color: #c68618 !important;
}

.action-delete {
  color: #ed4c5c !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.3px;
  min-width: unset !important;
  border: none !important;
}

.action-delete:hover {
  color: #c1071a !important;
}

.revert-order-item {
  display: flex;
  flex-direction: row-reverse;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .ant-space-item {
    padding-left: 24px;
  }
}

.dropdown-action {
  min-width: 120px;
  border-radius: 8px !important;

  .menu-action {
    border-radius: 20px;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-action {
    border-radius: 0px !important;
    padding: 12px 14px;
    text-align: left;
  }
}

.dropdown-box {
  .ant-dropdown-menu {
    border-radius: 10px !important;
  }
}

.action-review {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.3px;
  color: #50429b !important;
  background: unset !important;
  box-shadow: unset !important;
}

@media (max-width: 576px) {
  .revert-order-item {
    .ant-space-item {
      padding-left: 10px !important;
      padding-right: 10px !important;
      .btn-edit-customer {
        width: 75px;
      }
    }
    .ant-space-item:has(.btn-edit-customer) {
      padding-right: 0 !important;
    }
    .action-cancel {
      font-weight: 500 !important;
    }
    .action-delete {
      font-weight: 500 !important;
    }
  }
}

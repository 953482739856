.title-page-my-account {
  font-size: 32px;
  color: #2b2162;
  font-weight: bold;
  margin-bottom: 35px;
  margin-top: 0px;
}

.ant-tabs-tabpane {
    padding-left: 5px;
    padding-right: 5px;
}

.my-account-card {
    border-radius: 0 24px 24px 24px;
}

.modal-my-account {
  width: 60% !important;

  .ant-modal-content {
    border-radius: 40px;

    .ant-modal-close {
      top: 24px;
      width: 72px;
      height: 72px;
      border-radius: 20px;
      background: #463796;
      right: 24px;
      font-size: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-modal-close-x {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .ant-modal-header {
      height: 120px;
      border-radius: 40px 40px 0 0;
      background-color: #50429B;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-size: 32px;
        font-weight: 700;
        color: #fff;
        text-transform: capitalize;
      }

      .ant-modal-body {}
    }
  }
}

.btn-update-account {
    width     : 125px;
    background: var(--btn-color) !important;
    border    : 1px solid var(--btn-color) !important;

    color         : #fff !important;
    text-transform: capitalize !important;

    float: right;
}

.title-account {
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
}

.group-user-avatar {
  width: 100%;
  height: calc(100% - 118px);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .icon-camera {
    position: absolute;
    top: 45%;
    left: 58%;
    font-size: 32px;
    font-weight: bold;
    z-index: 3;
  }

  .user-avatar-no-border {
    border: unset !important;
  }

  .user-avatar {
    min-width: 200px;
    max-width: 30%;
    min-height: 200px;
    max-height: 100%;
    border-radius: 50%;
    border: 3px solid black;

    .image-uploaded {
      position: relative;
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
  }
}

.package-no-active-margin {
  margin-top: 135px;
}

.none-display{
  display: none !important;
}

.shop-title-header {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b2162;
  display: flex;
  margin: auto;
}

.shop-row-page-header {
  position: sticky;
  padding-bottom: 20px;
  padding-top: 12px;
  background: #f7f5ff;
  top: 0;
  z-index: 10;

  .page-title {
    float: left;
    margin-right: auto;
  }

  .page-action-group {
    float: right;
  }
}

@media (max-width: 500px) {
  .shop-title-header {
    font-size: 24px !important;
  }
}

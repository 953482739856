.top-dashboard {
  display: flex;
  align-items: center;

  .ant-col {
    .title-dashboard {
      font-family: "Plus Jakarta Sans";
      color: #50429b !important;
    }
  }

  .shop-form-btn-popover {
    .shop-row-top {
      margin-left: unset !important;
      margin-right: unset !important;
    }
  }
}

.group-business {
  height: 209px;
  border-radius: 16px;
  background: #50429b;
  color: #fff;
  padding: 32px 24px;

  .information-business {
    display: inline-flex;
    align-items: center;
    height: 50%;

    .top-business {
      margin-top: 32px;
      width: 100%;

      .ant-col {
        .status-business {
          display: inline-flex;
          align-items: start;
          width: 100%;

          .icon-increase-triangle {
            margin-top: 10px;
          }

          .label {
            color: #fff;
          }
        }
      }

      .title-business {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .total-business {
    padding-top: 32px;
    font-size: 36px;
    font-weight: 700;
  }

  .information {
    .footer {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .icon-cube {
    width: 56px !important;
    height: 56px !important;
  }

  .col-group-business {
    .group-business {
      height: 145px;
      width: 330px;
      padding: 24px !important;

      .information-business {
        .title-business {
          font-size: 18px;
        }

        .top-business {
          .content-business {
            padding-left: 25px;
          }

          .title-business {
            font-size: 24px;
            font-weight: 700;
          }
        }
      }

      .total-business {
        padding-left: 28px;
        padding-top: 18px;
        font-size: 18px;
      }
    }
  }

  .top-dashboard {
    flex-flow: row !important;
    row-gap: 0 !important;
    .ant-col-xs-24 {
      flex: auto;
    }
    .shop-form-btn-popover {
      .shop-row-top {
        grid-gap: 0 12px;
        flex-flow: nowrap;
        .btn-date-picker {
          display: block !important;
          width: 74px !important;
          .shop-btn-div-icon-calendar {
            background-color: #FFFFFF;
          }
          .shop-text-date-time {
            display: none !important;
          }
          .shop-div-down {
            display: none !important;
          }
        }
        .ant-btn-primary {
          height: 60px !important;
        }
      }
    }
  }
}

.text-min-w-sdsd {
  width: 100px;
  min-width: 100px;
}

.table-selling-product {
  .ant-table-thead {
    display: none;
  }
}

.table-selling-product-thumbnail {
  margin-left: 15px;
  margin-right: 13px;

  .thumbnail {
    object-fit: cover;
  }
}

.card-selling-product-thumbnail {
  .ant-card-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.table-selling-product-no {
  margin-top: auto;
  margin-bottom: auto;
}

.table-selling-product-text-no {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}

.table-selling-product-text-product-name {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  color: #000000;
  font-weight: 700;
  font-size: 18px;
}

.table-selling-product-name-mobile {
  position: absolute;
  margin-left: 93px;
}

.table-selling-product-item-mobile {
  position: absolute;
  margin-left: -100px;
  text-align: left;
  margin-top: -8px;
}

.table-selling-product-row {
  max-width: 100%;
  .table-selling-product-row-title {
    display: flex;
    align-items: flex-start;
  }
  .table-selling-product-row-description {
    text-align: end;
    margin-left: auto;
  }

  @media (max-width: 1440px) {    
    .table-selling-product-row-title {
      width: 100%;
    }
    .table-selling-product-row-description {
      width: 100%;
      text-align: start;
      margin-left: 93px;
    }
    .table-selling-product-name {
      max-width: 177px;
    }
  }
}

@media (min-width: 1024px) {
  .top-dashboard {
    display: block !important;
    .shop-form-btn-popover {
      max-width: 100%;
      .shop-row-top {
        flex-flow: row;
        grid-gap: 0 20px
      }
    }
  }
}

.table-selling-product-item-mobile-margin {
  margin-top: -47px;
}

.table-selling-product-name-overflow {
  max-width: 177px !important;
  white-space: nowrap;
}

@media only screen and (max-width: 1529px) {
  .home-page {
    &.table-selling-product-name-overflow {
      max-width: 85px !important;
    }
  }
}

@media only screen and (min-width: 1530px) and (max-width: 1650px) {
  .home-page {
    &.table-selling-product-name-overflow {
      max-width: 100px !important;
    }
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1870px) {
  .home-page {
    &.table-selling-product-name-overflow {
      max-width: 120px !important;
    }
  }
}

.table-selling-product-see-more {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #50429b;
  cursor: pointer;
}

.top-selling-product-card-width {
  width: 100%;
  height: 662px;
}

.table-customer-item-mobile-margin {
  margin-top: -17px;
}

.group-header-top-selling-product-box {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 22px;
}

.table-customer-row {
  height: 83px;
}

.table-selling-product-text-no-font-size {
  font-size: 14px;
}

.table-selling-product-see-more-text-align {
  text-align: right;
}

.table-emty-icon {
  margin-bottom: 12px;
  margin-top: 105px;
}

.table-emty-text {
  margin-bottom: 181px;
  color: #858585;
}

.table-selling-product-quantity-style {
  margin-top: 2px;
  font-size: 14px;
}

.recently-activities-card-width {
  height: 100% !important;
  .ant-card-body {
    height: 100%;
    .group-recently-activities {
      height: 100%;
      display: table;
      width: 100%;
      .group-header-top-activities-box {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        display: flex;
        align-items: center;
        color: #000000;
        height: auto;
      }
      .content-recently-activity-not-data {
        height: 100% !important;
      }
      .content-recently-activity-tablet {
        max-height: 607px;
        overflow-y: auto;
        margin-bottom: 24px;
        .item-content-recently-activities-non-data {
          display: flex;
          align-items: center;
          justify-content: center;
          .item-content-recently-activities-text-non-data {
            margin-top: 160px;
            margin-bottom: calc(160px - 24px);
          }
        }
        .item-content-recently-activities-background-white {
          background: #fff !important;
        }
        .item-content-recently-activities {
          background: #f9f8ff;
          width: 100%;
          align-items: center;
          border-radius: 16px;
          padding: 12px;
          .ant-image {
            .ant-image-img {
              height: auto !important;
            }
          }
          .item-content-recently-activities-body {
            padding-left: 12px;
          }
          .ant-col {
            .item-content-recently-activities-icon {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: start;
              .item-content-recently-activities-icon-image {
                width: 66px;
                height: 66px;
                border-radius: 12px;
              }
            }
            .item-content-period {
              font-size: 15px;
              padding-top: 8px;
            }
            .item-content-period:hover {
              cursor: pointer;
            }
            .paragraph-item-content-recently-activities {
              span {
                .item-content-recently-activities-user-name,
                .item-content-recently-activities-code {
                  color: #428bc1;
                  font-weight: bolder;
                }
                .item-content-recently-activities-user-name:hover,
                .item-content-recently-activities-code:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .content-recently-activity {
        height: calc(100% - 30px);
        overflow: auto;
        max-height: 100%;
        margin-top: 8px;
        .item-content-recently-activities-background-white {
          background: #fff !important;
        }
        .item-content-recently-activities {
          background: #f9f8ff;
          width: 100%;
          align-items: center;
          border-radius: 16px;
          padding: 12px;
          .item-content-recently-activities-body {
            padding-left: 12px;
          }
          .ant-col {
            .item-content-recently-activities-icon {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: start;
              .item-content-recently-activities-icon-image {
                width: 66px;
                height: 66px;
                border-radius: 12px;
              }
            }
            .item-content-period {
              font-size: 15px;
              padding-top: 8px;
              color: #858585;
              font-weight: 400;
            }
            .paragraph-item-content-recently-activities {
              span {
                .item-content-recently-activities-user-name,
                .item-content-recently-activities-code {
                  color: #428bc1;
                  font-weight: bolder;
                }
                .item-content-recently-activities-user-name:hover,
                .item-content-recently-activities-code:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .item-content-recently-activities-non-data {
          height: 100%;
          align-items: flex-start;
          justify-content: center;
          display: flex;
          .item-content-recently-activities-text-non-data {
            color: #858585;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            margin-top: 130px;
          }
        }
      }
    }
  }
}

.table-recent-activities {
  .ant-table-thead {
    display: none;
  }
}

// Responsive widget
@media only screen and (min-width: 1420px) {
  .row-width-auto {
    .col-with-auto {
      min-width: 360px;
      width: calc(100% / 3);
      .group-business {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1420px) {
  .row-width-auto {
    .col-with-auto {
      margin: auto;
    }
  }
}

.edit-blog {
  .edit-blog-overview-odd {
    padding: 15px;
    align-items: center;
    border-radius: 16px;
    background-color: #f7f5ff;
    word-break: break-word;
  }
  .edit-blog-overview-even {
    margin: 15px;
    align-items: center;
    border-radius: 16px;
    word-break: break-word;
  }

  .seo-warning-message {
    color: #50429b;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
    letter-spacing: 0.3px;
    display: flex;
    margin-top: 5px;

    svg {
      path {
        fill: #50429b;
      }
    }
    .icon-warning {
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }
    .text-warning {
      margin-left: 10px;
    }
  }
}
.edit-blog-overview{
  word-break: break-word;
}

.shop-image-select {
  .non-image {
    border: 1px dashed #9f9f9f;
    border-radius: 16px;
    height: auto;
    max-width: 100%;
    padding: 18px;
    background-color: #FFF;

    .justify-left {
      justify-content: left !important;
    }

    .image-product {
      min-height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: max-content !important;

      .upload-image-url {
        font-size: 18px;
        text-decoration: underline;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .text-non-image {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: #9F9F9F;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.3px;
         
    }
  }

  .have-image {
    border: unset !important;
    border-radius: unset !important;
    height: unset !important;
    text-align: left;
  }

  .border-error {
    border-color: #DB1B1B;
  }
}

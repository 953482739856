@import "/src/stylesheets/variable.scss";

.ant-drawer-body {
  position: relative;
}

.menu ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  width: 100% !important;
}

.menu ul li.ant-menu-item {
  padding-left: 12px !important;
}

.store-information {
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.store-label {
  position: relative;
}

.store-label::before {
  content: attr(title);
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #f1f1f1;
  color: #000;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.store-label:hover::before {
  opacity: 1;
}

.store-label::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 95%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #f1f1f1 transparent transparent transparent;
  z-index: 1;
}

.menu
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light
  > li.ant-menu-submenu.ant-menu-submenu-inline {
  margin: 24px 12px !important;
  width: auto !important;
}

//Home
.menu
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light
  > li.ant-menu-item {
  width: auto !important;
  font-size: 18px;
}

.menu.menu-mobile {
  overflow-y: scroll;
  max-height: 100%;
  padding-bottom: 105px;
}

.trigger-footer-mobile {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.logout-mobile {
  background-color: #f7f5ff;
  height: 57px !important;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #50429b;
  font-size: 20px;
  margin: 24px;
  cursor: pointer;
  .icon-logout {
    svg > path {
      fill: #50429b;
    }

    display: flex;
    margin-right: 10px;
  }
}

.account-popover-content {
  .log-out-border {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    font-family: "Plus Jakarta Sans";

    .avt-menu-icon {
      margin-top: 12px !important;
      margin-right: 18px !important;
    }

    padding-left: 30px !important;
  }

  .manage-account {
    margin-top: 5px !important;

    .avt-staff-icon {
      margin-top: 10px !important;
      margin-right: 18px !important;
    }

    padding-left: 30px !important;
  }

  .manage-account > a {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-family: "Plus Jakarta Sans";
  }

  .log-out-border > a {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-family: "Plus Jakarta Sans";
  }

  hr {
    border: 1px solid #dbd6ff;
  }

  width: 405px;
  box-sizing: border-box;
  background: #ffffff;
  padding-bottom: 0 !important;
  border-radius: 16px;
}

.avatar-account-popover {
  .header-avatar {
    cursor: pointer;
  }

  .header-avatar .ant-avatar.ant-avatar-circle {
    display: flex;
    align-items: center;
    color: $color-white;
    background: $color-light-purple-1;
    width: 100px;
    height: 100px;
    font-size: large;
  }

  .avatar-popover {
    margin: auto;
    margin-top: 12px;
  }

  .avatar-infor {
    text-align: center;
  }

  .store-info {
    .account-store-name > svg {
      margin-left: 16px;
      margin-top: 5px;
    }

    .account-store-name > span {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #50429b;
      margin-left: 58px;
      margin-top: -54px !important;
      position: absolute;
      width: 75%;
    }

    .account-store-address {
      .paragraph-style {
        max-width: 310px;
      }

      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .account-store-address > div {
      margin-left: 58px !important;
      margin-top: -50px !important;
    }

    .account-store-address > span {
      margin-left: 58px !important;
      margin-top: -10px !important;
      position: absolute;
    }

    background: #f7f5ff;
    border-radius: 16px;
    width: 377px;
    height: 102px;
    margin-left: 15px !important;
    margin-top: 18px !important;
  }

  .account-name {
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-size: 24px;
    color: #50429b;
    margin-top: -15px !important;
    word-break: break-all;
    max-width: 385px;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .account-email {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #676767;
    margin-top: -28px !important;
  }

  padding-bottom: 0 !important;
}

@media (max-width: 500px) {
  .avatar-top-bar {
    .ant-popover-inner {
      margin-right: 10px;
      width: 356px;
    }
  }

  .avatar-account-popover {
    .store-info {
      width: 328px;
    }
  }

  .account-popover-content {
    width: 356px;
  }
}

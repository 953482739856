.text-detail {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
}

@media (max-width: 576px) {
  .form-create-customer {
    flex-wrap: wrap;
    margin-bottom: 34.15px !important;
  }

  .shop-row-page-header {
    flex-direction: row;
    justify-content: flex-end;
  }

  .shop-row-page-header {
    display: inline-block !important;
    flex-direction: row;
    justify-content: flex-end;
    .ant-col-12:first-child {
      max-width: 100%;
      display: inline-block;
      .shop-title-header {
        text-transform: capitalize !important;
        font-size: 21px !important;
        position: relative;
        margin: 0;
      }
      svg {
        width: 25px;
        height: 25px;
        margin-left: 12px;
      }
    }
    .ant-col-12:last-child {
      margin-left: auto;
    }
  }

  .title-container {
    order: 2;
  }

  .button-container {
    order: 1;
    margin-top: 10px;
  }

  .form-row {
    margin-bottom: 0px !important;
    row-gap: 34.15px !important;
    .ant-row {
      margin: 0;
      margin-bottom: 0px !important;
    }

    .last-item {
      margin-bottom: 0 !important;
    }
  }

  .last-row {
    margin-top: 25px;
  }

  .form-gender {
    .ant-radio-group {
      label {
        margin-bottom: 25px;
        display: block;
        padding-left: 0px !important;
      }

      label:last-child {
        margin-bottom: 15px;
      }
    }

    height: auto !important;
    margin-top: 30px;
  }
}

.checkBox-margin {
  margin-top: 34px;
}

.note {
  textarea {
    min-height: 197px !important;
  }
}

.btn-add {
  min-width: 109px !important;
}

.title-group {
  margin-top: 10px;
  margin-bottom: 30px;
}

.shop-input-with-count .ant-input-suffix {
  line-height: 21px !important;
}

.form-row {
  margin-bottom: 10px;
}

// .shop-card {
//   .ant-card-body {
//     padding-bottom: 10px;
//   }
// }

.form-gender {
  display: block;
  height: 60px;

  .ant-form-item-control {
    margin: auto;
  }
}

.last-gender-option {
  padding-left: 50px !important;
}

.product-detail-form {
  .title-name {
    p {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      color: #000000;
    }
  }

  .shop-form-item-btn {
    display: flex;
    align-items: center;
    justify-content: end;

    .form-popover {
      margin-right: 12px;
    }

    .form-leave {
      margin-right: 20px;

      a {
        font-size: 18px;
        color: #999999;
        font-weight: 700;
        line-height: 24px;
        font-style: normal;
        display: contents;
        font-family: "Plus Jakarta Sans";
      }
    }

    .button-edit {
      width: 123px;
      height: 60px;
      border-radius: 16px;
      background-color: #ff8c21;

      span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        font-style: normal;
        font-family: Plus Jakarta Sans;
      }
    }
  }

  .product-detail-popover {
    .ant-popover-inner {
      width: 132px;
      height: 94px;
      background-color: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

      .div-item {
        display: grid;

        .text-activate {
          font-size: 18px;
          font-weight: 700;
          color: #f9cc66;
          line-height: 24px;
          font-style: normal;
          font-family: "Plus Jakarta Sans";
        }

        .text-delete {
          font-size: 18px;
          font-weight: 700;
          color: #ff0000;
          margin-top: 14px;
          line-height: 24px;
          font-style: normal;
          font-family: "Plus Jakarta Sans";
        }
      }
    }
  }

  .product-container {
    display: flex;
    flex-wrap: wrap;

    .padding-t-l-b {
      padding: 24px;
      margin-bottom: 24px;
    }

    .div-title {
      margin-bottom: 20px;
    }

    .product-form-left {
      width: 65%;
      padding: 5px;

      .card-genaral {
        border-radius: 30px;
        background-color: #ffffff;
      }
    }

    .text-name {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
      font-style: normal;
      letter-spacing: 0.3px;
      font-family: "Plus Jakarta Sans";
    }

    .product-form-right {
      width: 35%;
      padding: 0 5px 0 14px;

      .form-image {
        display: grid;
        background-color: #ffffff;
        border-radius: 30px;
        margin-bottom: 24px;
      }

      .form-category {
        height: 128px;
        border-radius: 30px;
        margin-bottom: 24px;
        background-color: #ffffff;
      }
    }

    .product-detail-div {
      display: grid;
      margin-bottom: 16px;

      .text-item {
        font-size: 20px;
        font-weight: 400;
        color: #9f9f9f;
        font-style: normal;
        font-family: "Plus Jakarta Sans";
      }
    }

    .text-total {
      color: #000000;
    }

    .text-cost {
      color: #50429b;
    }

    .text-unit-name {
      font-weight: 400;
    }

    .text-title {
      font-size: 24px;
      font-weight: 700;
      color: #000000;
      line-height: 30px;
      font-style: normal;
      font-family: "Plus Jakarta Sans";
    }

    .media {
      margin-bottom: 24px;
    }
  }

  .detail-product-dropdown-style {
    .ant-dropdown-menu {
      border-radius: 10px !important;
      .ant-dropdown-menu-item {
        a {
          font-size: 15px !important;
        }
      }
    }
  }
  .list-price {
    .col-title {
      display: flex;
      align-items: center;
      gap: 16px;
      .position-text {
        padding-bottom: 0;
      }
    }
    .price-item {
      padding: 16px;
      .text-name {
        display: flex;
        align-items: center;
      }
    }
    .box-product-price {
      align-items: center;
      display: flex;
    }
  }

@media (max-width: 1180px) {
  .position-mobile {
    align-self: start !important;
  }
}

@media (max-width: 500px) {
  .product-container {
    .padding-t-l-b {
      padding: 24px;
    }
  } 
  .shop-form-item-btn {
    padding-top: 16px;
    .revert-order-item {
      flex-direction: row-reverse;
    }
  }
  .grid-container-edit-product, .grid-container-create-product {
    display: flex;
  }
  .shop-input-number{
    height: 48px !important;
  }
  .icon-delete-price {
    align-items: start !important;
  }
  .list-price {
    margin-left: 0 !important;
    .price-item {
      background-color: #FBFAFF;
      border-radius: 16px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .drag-icon {
      margin-left: 1em;
      margin-bottom: 0.5em;
      margin-top: 18px;
    }
    .col-title {
      .title-center {
        align-self: flex-start;
      }
      .position-text {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 0.5em;
      }
      .position-mobile {
        margin-top: 0px !important;
      }
    }
    .ant-form-item-explain-error {
      font-size: 14px;
    }
  }
  .content-img {
    margin-left: 0;
  }
  .ant-typography{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .product-form-right {
    padding: 0 !important;
  }
}

  @media (max-width: 600px) {
    .product-container {
      display: block;

      .product-form-left,
      .product-form-right {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    .product-container {
      display: block;

      .product-form-left,
      .product-form-right {
        width: 100%;
      }
    }
  }

  @media (min-width: 900px) {
    .product-container {
      display: block;

      .product-form-left {
        width: 65%;
      }

      .product-form-right {
        width: 35%;
      }
    }
  }

  @media (min-width: 1200px) {
    .product-container {
      display: flex;

      .product-form-left {
        width: 65%;
      }

      .product-form-right {
        width: 35%;
      }
    }
  }

  @media (max-width: 1200px) {
    .product-container {
      display: block;

      .product-form-left,
      .product-form-right {
        width: 100%;
      }
    }
  }
}

.delete-confirm-modal {
  button {
    margin-left: 10px;
    margin-right: 10px;
  }

  .ant-modal-content {
    border-radius: 48px;
  }
  .ant-modal-header {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: #ecf1f9 !important;
    height: 102px;
    position: relative;
  }
  .ant-modal-title {
    width: 100%;
    color: #50429b !important;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .ant-modal-body {
    text-align: center;
    color: #000000;
    line-height: 36px;
    font-weight: 500;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (min-width: 500px) {
    .ant-modal-body {
      font-size: 24px !important;
    }
  }
  @media (max-width: 500px) {
    .ant-modal-body {
      font-size: 22px !important;
    }
  }
  
  .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 8%;
  }
  .ant-btn {
    height: 55px;
    min-width: 183px;
    border-radius: 16px;
  }
  .ant-btn-default {
    min-width: 180px;
    border: 1px solid #a5abde;
    color: #a5abde;
  }
  .ant-btn-default:hover {
    border: 1px solid #a5abde;
    color: #a5abde;
  }
  .ant-btn-dangerous {
    background-color: #fc0d1b;
    color: #ffffff;
    border: none;
  }
  .ant-btn-dangerous:hover {
    background-color: #d90b17;
    color: #ffffff;
    border: none;
  }
}
.style-text-confirm-delete {
  color: #fc0d1b;
}

.style-text-confirm-stop {
  color: #ff8c21;
}

.action-delete-border {
  background-color: #fff;
  color: #ed4c5c !important;
  border: 1px solid #ed4c5c !important;
  min-width: 50px !important;
}

.style-text-confirm-delete-border {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500 !important;
  line-height: 36px;
  color: #50429b;
  overflow-wrap: anywhere;
}

.confirm-stop-modal-sizing {
  width: 500px !important;
}

.confirm-stop-modal-sizing > .ant-modal-content > .ant-modal-header {
  height: 88px !important;
  font-size: 20px !important;
}

.confirm-stop-modal-sizing > .ant-modal-content > .ant-modal-body {
  height: 132px;
  padding: 12px 24px !important;
  font-size: 20px !important;
}

$screen_md: 768px;
$screen_sm: 575px;
$popover_picker_md: 458px;
$popover_picker_sm: 100vw;

.shop-popver-picker {
  .shop-form-picker {
    background-color: #ffffff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex !important;

    .rdrDateDisplayWrapper {
      display: none !important;
    }

    .rdrMonthAndYearWrapper {
      align-items: center;
      height: 30px;
      padding-top: 0px;
    }

    .rdrMonthAndYearPickers {
      justify-content: left;
    }

    .rdrMonthName {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      background-color: #50429b;
      height: 40px;
      border-radius: 16px;
      margin-left: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rdrWeekDays {
      .rdrWeekDay {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #19181a;
      }
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: rgb(0 0 0 / 85%);
    }

    .rdrDay {
      padding: 20px;
      height: 40px;
    }

    .rdrDayNumber {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #19181a;
      right: 5px;
    }

    .rdrDateRangeWrapper {
      margin-top: 25px;

      .rdrMonths {
        margin-top: 16px;
      }

      .ant-select-selection-item {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #19181a;
        align-items: center;
        padding-top: 5px;
        height: 40px;
      }

      .select-months {
        width: 175px;
      }

      .select-years {
        min-width: 100px;
      }

      .ant-select-focused:not(.ant-select-disable).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #f8f7fa;
        border-radius: 16px;
        border-color: #ffffff;
        box-shadow: none;
        border-right-width: none;
        outline: 0;
        height: 40px;
        align-items: center;
        padding-top: 5px;
        span {
          color: #50429b;
        }
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0px;
      }
    }

    .rdrStartEdge,
    .rdrEndEdge {
      height: 40px;
      width: 40px;
      top: 0px;
      left: 0px;
      background-color: #50429b;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .rdrDayNumber {
        span {
          color: #f8f8f8;
        }
      }
    }

    .rdrInRange {
      background-color: #eae7ff;
    }

    .rdrDayToday .rdrDayNumber span:after {
      background: #50429b;
      display: none;
    }

    .rdrDayToday {
      background: #f8f7fa;
      border-radius: 50%;
      .rdrDayNumber span {
        color: #50429b;
      }
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview {
      color: #50429b !important;
      height: 40px;
      width: 40px;
      top: 0px;
      left: 0px;
      border-radius: 50%;
    }

    .rdrDayInPreview {
      color: #50429b !important;
    }

    .shop-form-option-date {
      display: inline-grid !important;
      background-color: #fbfbfb;
      border-radius: 16px 0px 0px 0px;
      .ant-radio-group {
        margin: 16px 8px 16px 8px;

        .ant-radio-button-wrapper {
          width: 142px;
          background-color: #fbfbfb;
          border-right: none;
          border-radius: 8px;
        }

        .ant-radio-button-wrapper.shop-btn-date::before {
          width: 0px;
        }
      }

      .shop-btn-date.ant-radio-button-wrapper-checked {
        span {
          color: #ffffff;
        }
      }

      .shop-btn-date {
        height: 43px;
        border-color: #fbfbfb;

        align-items: center;
        padding-left: 20px;
        text-align: left;
        display: flex;

        .ant-radio-button-checked {
          border-radius: 8px;
          background-color: #50429b;
        }

        span {
          color: #000000;
          font-size: 18px;
          font-family: "Plus Jakarta Sans";
          text-align: center;
          font-style: normal;
          font-weight: 500;
        }
      }

      .shop-btn-date:hover {
        border-radius: 8px;
        background-color: #50429b;
        span {
          color: #ffffff;
        }
      }

      .shop-btn-date-disable.ant-radio-button-wrapper-checked {
        border-radius: 8px;
        background-color: #50429b;
      }
    }

    .shop-form-picker {
      display: inline-grid;
    }
  }
}

.shop-popver-picker .shop-form-picker .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #19181a;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: #ffffff;
}

.btn-date-picker {
  width: 332px;
  height: 60px;
  padding: 0px !important;
  border-radius: 12px;
  display: flex !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;

  .shop-btn-div-icon-calendar {
    width: 42px;
    height: 42px;
    display: flex;
    margin-top: 9px;
    margin-left: 16px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
  }

  .shop-div-text-date-time {
    width: 215px;
    padding-left: 15.57px;
    align-self: end;

    .shop-text-date-time {
      font-size: 18px;
      font-family: "Plus Jakarta Sans";
      color: #000000;
      display: inherit;
      overflow: hidden;
      max-width: 210.43px;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: initial;
    } 
  }

  .shop-div-down {
    width: 43.43px;
    align-self: center;

    .shop-icon-down {
      font-size: 1.5px;
      color: #292d32;
      width: 18px;
      height: 18px;
    }
  }

  @media screen and (max-width: $screen_md) {
    width: fit-content;

    .shop-btn-div-icon-calendar {
      margin-top: 6px;
      margin-left: 8px;
    }

    .shop-div-text-date-time {
      width: 137px;
      padding-left: 7px;
    }

    .shop-div-down {
      width: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media screen and (max-width: $screen_sm) {
    .shop-div-down {
      width: 18px;
      .shop-icon-down {
        font-size: 1.5px;
        color: #292d32;
        width: 14px;
        height: 7px;
      }
    }
  }
}

.shop-footer {
  justify-content: end;
  border: solid 1px white;
  border-top-color: #efefef;
  background-color: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;

  .shop-footer-div {
    align-self: center;
    margin-right: 20px;

    .shop-text-date {
      font-size: 15px;
      color: #000000;
      font-family: "Plus Jakarta Sans";
      align-self: center;
      text-align: center;
      display: contents;
    }

    .shop-date-to-date-wrapper {
      font-size: 15px;
      font-family: "Plus Jakarta Sans";

      .shop-text-start-date {
        color: #000000;
        margin-right: 15px;
      }

      .shop-text-to-date {
        color: #979797;
        margin-right: 15px;
      }
    }
  }

  .shop-btn-cancel {
    width: 153px;
    height: 55px;
    border-radius: 8px;
    background-color: #f9f9f9;
    border: 1px solid #d8d8d8;
    color: #858585;
  }

  .shop-btn-apply {
    width: 153px;
    height: 55px;
    border-radius: 8px;
    background-color: #ff8c21;
    color: #f9f9f9;
    font-family: "Plus Jakarta Sans";
    margin-right: 28px;
    margin-left: 19px;
  }
}

.dropdown-months {
  .ant-select-item {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #19181a;
    margin: 0px 5px;
    border-radius: 8px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 600;
    color: #50429b;
    background-color: #ffffff;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #50429b;
    border-radius: 8px;
    color: #ffffff;
  }
}

.dropdown-years {
  .ant-select-item {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #19181a;
    margin: 0px 10px;
    border-radius: 8px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 600;
    color: #50429b;
    background-color: #ffffff;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #50429b;
    border-radius: 8px;
    color: #ffffff;
  }
}

@media only screen and (max-width: $screen_md) {

  .shop-popver-picker {
    width: $popover_picker_md !important;

    .shop-form-picker {
      width: $popover_picker_md !important;
      display: block !important;
      overflow: hidden;

      .shop-form-option-date {
        .ant-radio-group {
          display: flex;
          gap: 4px;
          margin: 0;
          padding: 16px 8px;
          width: $popover_picker_md !important;
          overflow-x: scroll;

          .shop-btn-date {
            width: 138px;
            height: 43px;

            span {
              width: 138px;
              font-size: 15px;
            }
          }
        }
      }

      .rdrCalendarWrapper.rdrDateRangeWrapper {
        width: $popover_picker_md !important;

        .rdrMonthAndYearWrapper {
          .rdrMonthAndYearPickers {
            width: $popover_picker_md;
            display: flex;
            justify-content: center;

            .rdrNextPrevButton {
              margin: 0;
            }
          }
        }

        .rdrMonthName {
          margin: auto;
          margin-top: 15px;
        }
        

        .rdrMonths.rdrMonthsHorizontal {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .shop-footer {
      width: $popover_picker_md;
      display: flex;
      justify-content: center;
      gap: 20px;

      .shop-footer-div {
        margin: 0;
        width: 100%;
        text-align: center;

        .shop-date-to-date-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .shop-form-btn {
        display: flex;
        justify-content: center;
        gap: 24px;

        .shop-btn-cancel,
        .shop-btn-apply {
          width: auto;
          height: auto;
          min-width: 100px;
          min-height: 42px;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}




@media only screen and (max-width: $screen_sm) {
  .shop-popver-picker {
    width: $popover_picker_sm !important;

    .shop-form-picker {
      width: $popover_picker_sm !important;

      .shop-form-option-date {
        .ant-radio-group {
          width: $popover_picker_sm !important;
        }
      }

      .rdrCalendarWrapper.rdrDateRangeWrapper {
        width: $popover_picker_sm !important;

        .rdrMonthAndYearWrapper {
          .rdrMonthAndYearPickers {
            width: $popover_picker_sm;
          }
        }
      }
    }

    .shop-footer {
      width: $popover_picker_sm;
    }
  }
}

.input-blur-effect {
  border-radius: 12px !important;
  border: none !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

@import '../../stylesheets/variable';
.ant-segmented-item {
  background: $color-purple;
  width: 150px;
  font-size: medium;
  color: white;
  margin: 0 1px;
  border-radius: 12px;
  transition: red 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    color: white;
    background-color: $color-A5ABDE;
  }
}
.ant-segmented-item-selected {
  background-color: $color-A5ABDE;
  color: white;

  .ant-segmented-item-label > * {
    background: transparent;
  }
}
.ant-badge-count {
  background: $color-FC0D1B;
  box-shadow: none;
}
.ant-segmented {
  background-color: transparent;
}
.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background: none;
}
.ant-segmented-item-label {
  padding: 0;
  border-radius: 10px;
  transition: none;
}
// .new-order-color{
//     background-color: $color-F9CC66;
//     .ant-btn-primary {
//         background-color: $color-F9CC66;
//     }
// }
// .returned-order-color{
//     background-color: $color-light-red;
//     .ant-btn-primary {
//         background-color: $color-light-red;
//     }
// }
// .canceled-order-color{
//     background-color: $color-red;
//     .ant-btn-primary {
//         background-color: $color-red;
//     }
// }
// .to-confirm-order-color{
//     background-color: $color-FF8C21;
//     .ant-btn-primary {
//         background-color: $color-FF8C21;
//     }
// }
// .processing-order-color{
//     background-color: $color-FF8C21;
//     .ant-btn-primary {
//         background-color: $color-F9CC66;
//     }
// }
// .delivering-order-color{
//     background-color: $color-light-blue;
//     .ant-btn-primary {
//         background-color: $color-light-blue;
//     }
// }
// .completed-order-color{
//     background-color: $color-33B530;
//     .ant-btn-primary {
//         background-color: $color-33B530;
//     }
// }
